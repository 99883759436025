import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs';

@Injectable()
export class SeoService {
  constructor(
    private title: Title,
    private translateService: TranslateService
  ) {}

  public setSeoParams(seo: string | undefined): void {
    if (seo) {
      this.updateTitle(`SEO.TITLE.${seo}`);
    } else {
      this.setDefaultTitle();
    }
  }
  public setDefaultTitle(): void {
    this.updateTitle('SEO.TITLE.DEFAULT');
  }

  public updateTitle(titleLocale: string): void {
    if (titleLocale) {
      this.translateService
        .stream(titleLocale)
        .pipe(take(1))
        .subscribe((title) => {
          const updatedTitle = (newValue = ''): void => {
            this.title.setTitle(newValue);
          };
          if (title) {
            updatedTitle(title);
          }
        });
    }
  }
}
